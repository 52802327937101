import {
  ActionIcon,
  Collapse,
  Group,
  Skeleton,
  Title,
  TitleProps,
  UnstyledButton,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useGetChartLabels } from 'api/chartLabels'
import { useGetChartMeasurements } from 'api/measurements'
import { useDashboardStore } from 'components/dashboards/hooks/useDashboardStore'
import { ColumnTypeENUM } from 'data/columnType'
import dayjs from 'dayjs'
import { useDisclosureState } from 'hooks/useDisclosureState'
import { Fragment, memo, useMemo } from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import { TbChevronDown } from 'react-icons/tb'
import { useSearchParams } from 'react-router-dom'
import { TIndicator } from 'shared/types'
import { useCustomizerStore } from 'store/customizerStore'
import { findMinAndMaxValueForChart } from 'utils/findMinAndMaxValueForChart'
import { LabelPopover } from '../labels/LabelPopover'
import { LastMeasurement } from './LastMeasurement'
import { NumericalValue } from './NumericalValue'
import { useAreaChartOptions } from './useAreaChartOptions'

export type MeasurementAreaChartProps = {
  indicator: TIndicator['code']
  deviceId: string
  name?: string
  disabled?: boolean
  collapsable?: boolean
  titleProps?: TitleProps
  columnType?: number
}

export type TLabelPopoverState = {
  chart: ApexChart
  xasix: { min: number; max: number }
  deviceId: string
  indicatorCode: string
}

export const MeasurementAreaChart = memo(
  ({
    deviceId,
    indicator,
    name,
    columnType,
    disabled,
    collapsable,
    titleProps,
  }: MeasurementAreaChartProps) => {
    const extendedChartsView = useCustomizerStore(
      state => state.extendedChartsView,
    )
    const [opened, { toggle }] = useDisclosure(false)
    const [params, setParams] = useSearchParams()
    const queryParams = Object.fromEntries(params.entries())

    const isEditMode = useDashboardStore(state => state.isEditMode)
    const { isOpen, close, open, state } =
      useDisclosureState<TLabelPopoverState>(false)
    const measurements = useGetChartMeasurements(deviceId, indicator)
    const labels = useGetChartLabels({
      deviceId: deviceId!,
      indicatorCode: indicator,
    })

    const chartType = useMemo(() => {
      switch (columnType) {
        case ColumnTypeENUM.LinearWith0:
        case ColumnTypeENUM.AutomaticAutoScale:
          return 'area'
        case ColumnTypeENUM.Columnar:
          return 'bar'
        default:
          return 'numericalValue'
      }
    }, [columnType])

    const parsedMeasurements = useMemo(() => {
      if (!measurements?.data || !measurements?.data?.length) {
        return []
      }
      return measurements?.data?.map?.(item => item?.value)
    }, [measurements])

    const { series, options } = useAreaChartOptions({
      disableToolbar: disabled,
      deviceId,
      title: indicator,
      measurements,
      labels,
      yaxisMinValue:
        columnType === ColumnTypeENUM.AutomaticAutoScale
          ? undefined
          : Number(findMinAndMaxValueForChart?.(parsedMeasurements)?.min),
      yaxisMaxValue:
        columnType === ColumnTypeENUM.AutomaticAutoScale
          ? undefined
          : Number(findMinAndMaxValueForChart?.(parsedMeasurements)?.max),
      events: {
        selection: (chart, { xaxis }) => {
          open({
            chart,
            deviceId: deviceId!,
            indicatorCode: indicator,
            xasix: xaxis as {
              min: number
              max: number
            },
          })
        },
        zoomed(_chart, { xaxis }) {
          const fromDate = dayjs(xaxis.min).toISOString()
          const toDate = dayjs(xaxis.max).toISOString()

          setTimeout(() => setParams({ ...queryParams, fromDate, toDate }), 10)
        },
      },
    })

    const { isLoading, isSuccess } = measurements
    if (isLoading && !collapsable) return <Skeleton animate height={300} />
    if (isSuccess && !collapsable)
      return (
        <LabelPopover {...{ isOpen, close, open, state }}>
          <div style={{ pointerEvents: isEditMode ? 'none' : 'auto' }}>
            {extendedChartsView ? (
              <>
                <MeasurementHeader
                  name={name}
                  deviceId={deviceId!}
                  indicatorCode={indicator}
                  disabled={
                    disabled || columnType === ColumnTypeENUM.NumericalValue
                  }
                  titleProps={titleProps}
                />
                {chartType !== 'numericalValue' ? (
                  <Chart
                    height={350}
                    type={chartType}
                    options={options}
                    series={series}
                  />
                ) : (
                  <NumericalValue indicator={indicator} deviceId={deviceId} />
                )}
              </>
            ) : (
              <>
                <MeasurementHeader
                  name={name}
                  deviceId={deviceId!}
                  indicatorCode={indicator}
                  disabled={
                    disabled ||
                    (columnType === ColumnTypeENUM.NumericalValue &&
                      extendedChartsView)
                  }
                  titleProps={titleProps}
                />
              </>
            )}
          </div>
        </LabelPopover>
      )

    if (isLoading && collapsable) return <Skeleton animate height={35} />
    if (isSuccess && collapsable)
      return (
        <div>
          <MeasurementHeader
            onCollapse={toggle}
            deviceId={deviceId!}
            name={name}
            indicatorCode={indicator}
            disabled={disabled || columnType === ColumnTypeENUM.NumericalValue}
            titleProps={titleProps}
          />
          <Collapse in={opened}>
            {chartType !== 'numericalValue' ? (
              <Chart
                height={350}
                type={chartType}
                options={options}
                series={series}
              />
            ) : (
              <NumericalValue indicator={indicator} deviceId={deviceId} />
            )}
          </Collapse>
        </div>
      )
    return null
  },
)
MeasurementAreaChart.displayName = 'MeasurementAreaChart'

export const MeasurementHeader = ({
  indicatorCode,
  name,
  deviceId,
  disabled,
  onCollapse,
  titleProps,
}: {
  indicatorCode: string
  name?: string
  disabled?: boolean
  deviceId: string
  onCollapse?: () => void
  titleProps?: TitleProps
}) => {
  const { t } = useTranslation()

  const Component = onCollapse ? UnstyledButton : Fragment

  return (
    <Component
      {...(onCollapse ? { style: { width: '100%' }, onClick: onCollapse } : {})}
    >
      {name ? (
        <Title
          order={3}
          c="#0e9f27"
          style={{
            wordWrap: 'break-word',
          }}
        >
          {name}
        </Title>
      ) : null}
      <Group mb="sm" position="apart" spacing={5} align="center">
        <Title order={5} {...titleProps}>
          {t(`indicator.${indicatorCode}`)}
        </Title>
        {disabled ? null : (
          <LastMeasurement indicator={indicatorCode} deviceId={deviceId!} />
        )}
        {onCollapse !== undefined && (
          <ActionIcon>
            <TbChevronDown />
          </ActionIcon>
        )}
      </Group>
    </Component>
  )
}
