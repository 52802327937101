import { Alert, Card, Text } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { createDashboard } from 'api/dashboards/dashboardCreate'
import { useGetAvailableDashboards } from 'api/dashboards/getAvailableDashboards'
import { useGetDashboard } from 'api/dashboards/getDashboard'
import withSignalR from 'components/common/SignalRProvider'
import { CreateDashboardForm } from 'components/dashboards/DashboardCreate'
import { DashboardProvider } from 'components/dashboards/DashboardProvider'
import { r } from 'config/routes'
import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CiWarning } from 'react-icons/ci'
import { Link, useParams } from 'react-router-dom'
import { TDeviceType } from 'views/panel/Devices/RegisterDevice/api/registerDevice'
import { Loader } from '..'

export const DeviceIndicators =
  /* withSignalR( */
  ({
    deviceModelId,
    deviceType,
  }: {
    deviceModelId: string | null
    deviceType: TDeviceType
  }) => {
    const { t } = useTranslation()
    const { deviceId } = useParams()

    const [dashboardId, setDashboardId] = useState<string | undefined>(
      undefined,
    )

    const query = useGetAvailableDashboards({
      deviceId: deviceId!,
      modelId: deviceModelId,
    })

    const defaultDashboardId =
      query.isSuccess && query.data.length > 0
        ? query.data.find(({ isDefault }) => isDefault)?.id || query.data[0].id
        : undefined

    const { isSuccess, data, isLoading } = useGetDashboard({
      deviceId,
      dashboardId: dashboardId || defaultDashboardId,
    })

    return (
      <DashboardIdContext.Provider value={{ dashboardId, setDashboardId }}>
        <DeviceModelContext.Provider value={deviceModelId}>
          <DeviceTypeContext.Provider value={deviceType}>
            <Card>
              {deviceModelId ? (
                <>
                  {query.isSuccess && query.data.length === 0 && (
                    <NoDashboardAlert />
                  )}
                  {isLoading && !query.isError && query.data?.length !== 0 && (
                    <Loader />
                  )}
                  {isSuccess && data !== null && (
                    <DashboardProvider data={data} onChange={query.refetch} />
                  )}
                </>
              ) : (
                <Alert
                  color="red"
                  icon={<CiWarning />}
                  title={t('loraDevices.noModelAssigned')}
                >
                  {t('loraDevices.noModelAssignedFirstPartNote')}{' '}
                  <Link
                    to={r['loraDevices.edit'](deviceId, 'general')}
                    style={{ textDecoration: 'none' }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        color: 'green',
                      }}
                    >
                      {t('generic.here')}
                    </span>
                  </Link>{' '}
                  {t('loraDevices.noModelAssignedSecondPartNote')}
                </Alert>
              )}
            </Card>
          </DeviceTypeContext.Provider>
        </DeviceModelContext.Provider>
      </DashboardIdContext.Provider>
    )
  } /* ,
) */

const DashboardIdContext = createContext<{
  dashboardId: string | undefined
  setDashboardId: React.Dispatch<React.SetStateAction<string | undefined>>
} | null>(null)
export const useDashboardIdContext = () => {
  const context = useContext(DashboardIdContext)
  if (!context) throw new Error('Missing context data')
  return context
}

const DeviceModelContext = createContext<string | null>(null)
export const useDeviceModelContext = () => useContext(DeviceModelContext)

const DeviceTypeContext = createContext<TDeviceType | null>(null)
export const useDeviceTypeContext = () => {
  const deviceType = useContext(DeviceTypeContext)
  if (deviceType === null) throw new Error('Missing device type info')

  return deviceType
}

const NoDashboardAlert = () => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const modelId = useDeviceModelContext()
  const { setDashboardId } = useDashboardIdContext()

  const onClick = () => {
    openModal({
      title: t('dashboard.create'),
      children: (
        <CreateDashboardForm
          mutationFn={createDashboard({ deviceId: deviceId! })}
          deviceId={deviceId!}
          modelId={modelId}
          setDashboardId={setDashboardId}
        />
      ),
    })
  }
  return (
    <Alert
      color="red"
      icon={<CiWarning />}
      title={t('loraDevices.noDashboards')}
    >
      {t('loraDevices.noDashboardsFirstPartNote')}{' '}
      <Text
        onClick={onClick}
        style={{
          display: 'inline',
          fontWeight: 600,
          color: 'green',
          cursor: 'pointer',
        }}
      >
        {t('generic.here')}
      </Text>{' '}
      {t('loraDevices.noDashboardsSecondPartNote')}
    </Alert>
  )
}
